import GLightbox from 'glightbox';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { CloseButton } from 'components/elements';
import styles from './VideoModalBody.module.scss';
import {
  closeVideoModal,
  selectVideoModal,
} from 'store/videoModal/videoModal.slice';
import { useEffect, useRef } from 'react';

export const VideoModalBody = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const videoModal = useSelector(selectVideoModal);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const videoGallery = GLightbox({
      elements: [
        {
          href: videoModal.videoSrc,
          type: 'video',
          source: 'local', //vimeo, youtube or local
          width: 1200,
        },
      ],
      autoplayVideos: true,
    });

    // const image = document.querySelector('.screen__image');
    ref.current?.addEventListener('click', () => {
      videoGallery.open();
    });
  }, []);

  return (
    <div className={styles.module}>
      <CloseButton
        className={styles.close}
        onClick={() => dispatch(closeVideoModal())}
      />

      <h2 className={styles.title}>{videoModal.title}</h2>

      <p className={styles.subtitle}>{videoModal.subtitle}</p>

      <div className={styles.image} ref={ref}>
        <img src={videoModal.imageSrc} alt={videoModal.title} />
        <span></span>
      </div>
    </div>
  );
};
