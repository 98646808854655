import { configureStore, Action, ThunkAction } from '@reduxjs/toolkit';
import { appReducer } from './app/app.slice';
import { callbackReducer } from './callback/callback.slice';
import { formReducer } from './form/form.slice';
import { useDispatch } from 'react-redux';
import { bookingReducer } from './booking/booking.slice';
import { quizReducer } from './quiz/quiz.slice';
import { reviewReducer } from './review/review.slice';
import { addReviewReducer } from './addReview/addReview.slice';
import { questionReducer } from './question/question.slice';
import { videoModalReducer } from './videoModal/videoModal.slice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    callback: callbackReducer,
    form: formReducer,
    booking: bookingReducer,
    quiz: quizReducer,
    review: reviewReducer,
    addReview: addReviewReducer,
    question: questionReducer,
    videoModal: videoModalReducer,
  },
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type AppThunk<ReturnType = Promise<void>> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
