import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { Portal, Transition } from 'components/other';
import { Modal } from 'components/layout';
import { VideoModalBody } from './VideoModalBody/VideoModalBody';
import {
  closeVideoModal,
  selectVideoModal,
} from 'store/videoModal/videoModal.slice';

export const VideoModal = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const videoModal = useSelector(selectVideoModal);

  return (
    <Transition isActive={videoModal.ui.videoModalIsOpen} timeout={300}>
      {(transitionStyles) => (
        <Portal withBlocking>
          <Modal
            onOverlayClick={() => dispatch(closeVideoModal())}
            className={transitionStyles}
          >
            <VideoModalBody />
          </Modal>
        </Portal>
      )}
    </Transition>
  );
};
