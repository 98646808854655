import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { VideoModalState } from './videoModal.types';

const initialState: VideoModalState = {
  title: '',
  subtitle: '',
  imageSrc: '',
  videoSrc: '',
  ui: {
    videoModalIsOpen: false,
  },
};

export const videoModalSlice = createSlice({
  name: 'videoModal',
  initialState,
  reducers: {
    openVideoModal: (
      state,
      {
        payload,
      }: PayloadAction<{
        title: string;
        subtitle: string;
        imageSrc: string;
        videoSrc: string;
      }>,
    ) => {
      state.title = payload.title;
      state.subtitle = payload.subtitle;
      state.imageSrc = payload.imageSrc;
      state.videoSrc = payload.videoSrc;
      state.ui.videoModalIsOpen = true;
    },
    closeVideoModal: (state) => {
      state.ui.videoModalIsOpen = false;
    },
  },
});

export const { openVideoModal, closeVideoModal } = videoModalSlice.actions;

export const selectVideoModal = (state: RootState) => state.videoModal;

export const videoModalReducer = videoModalSlice.reducer;
